<template>
  <form @input="$emit('input', formattedLetterWords)">
    <div v-for="(item, i) in letterWords" :key="i">
      <input type="text" v-model="item.answer" placeholder="Answer" />
      <input type="text" v-model="item.data" placeholder="List. Ex: one, tow, seven / three / four" />
      <input type="text" v-model="item.src" placeholder="Audio link" />
    </div>

    <button type="button" @click="addItem">
      + Add Item
    </button>
  </form>
</template>

<script>
// "letterWords": [
// {
//   "answer": "Cat",
//   "src": "/type-9-a/cat.mp3",
//   "data": [
//     "Cat",
//     "Baby",
//     "Ball",
//     "Bedroom",
//     "Elephant",
//     "Nose"
//   ]
// },
// ]

export default {
  name: 'Type9a',
  data() {
    return {
      letterWords: [
        {
          answer: '',
          src: '',
          data: '',
        },
      ],
    };
  },
  computed: {
    formattedLetterWords() {
      let result = JSON.parse(JSON.stringify(this.letterWords));
      result = result.map((item) => {
        item.data = item.data
            .trim()
            .replace(/[\,\/]/gi, ',')
            .split(',');
          return item;
      });

      console.log(result);

      return result;
    },
  },
  methods: {
    addItem() {
      this.letterWords.push({
        answer: '',
        src: '',
        data: '',
      });
    },
  },
};
</script>
